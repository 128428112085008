import React from 'react'

/**
 * Not Found Page
 * If user enters a URL path that does not exist
 * Default will return this content below
 */
const NotFoundPage = () => (
  <div
    id="collins-website-not-found-page"
    css={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'center',
    }}
  >
    <h1 id="cw-nfp-h1">Page not found</h1>
    <p id="cw-nfp-message">You just hit a route that doesn&#39;t exist...</p>
    <a id="cw-nfp-link" href="https://rochesterorthodontics.com/">
      Return to Collins Orthodontics home page
    </a>
  </div>
)

export default NotFoundPage
